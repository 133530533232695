.ant-checkbox-wrapper {
    & .ant-checkbox {
  
      & .ant-checkbox-inner {
        width: 20px;
        height: 20px;
        border: 1px solid $color-grey;
        border-radius: 3px;
      }
  
      &:hover .ant-checkbox-inner, 
      & .ant-checkbox:hover .ant-checkbox-inner, 
      & .ant-checkbox-input:focus + .ant-checkbox-inner {
          border-color: $color-primary;
          border-radius: 3px;
      }
    }
  
    & .ant-checkbox-inner::after {
      left: 30%;
    }
  
    & .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $color-primary !important;
      border-color: $color-primary !important;
      // background-color: #4f66a0 !important;
      // border-color: #4f66a0 !important;
  
      &::after {
        border: 2px solid $color-white;
        border-top: 0;
        border-left: 0;
      }
    }
  
    & .ant-checkbox-input:focus + .ant-checkbox-inner {
      box-shadow: 0px 0px 5px 1px $color-primary-3;
    }
  }
  
  