html {
	min-height:100%;
	height:100%;
}

body {
	background: $color-white;
	// min-width:320px;
	// height:100%;
}

#__next {
	height:100%;
}


.ant-layout-sider-dark {
	// background: #231f20;
  background: $color-white;
	// position: fixed;
	// z-index: 2;
	// height: 100vh;
}

*::-webkit-scrollbar {
	width: 6px;
	&-track {
		background: $color-light-grey;
	}
	&-thumb {
		background: #D5DFE8;
		border-radius: 3px;
	}
}
* {
	scrollbar-color: #D5DFE8 $color-light-grey;
  scrollbar-width: 6px;
}
// .sidebar-inner {
// 	max-height: unset!important;
// 	overflow-y: unset!important;
// }



// #admin-layout {
// 	height: 100%;
// 	overflow: hidden;

// 	section {
// 		padding:20px 0;

// 		&:first-child {
// 			padding-top:0;
// 		}

// 		&:last-child {
// 			padding-bottom: 0;
// 		}

// 		&.ant-layout {
// 			padding: 0;
// 			overflow: hidden;
// 		}
// 	}

// 	.ant-layout-sider {
// 		background: #2c2738; 
// 		min-height: 100vh;
// 		z-index: 150;
// 		overflow-x: hidden;
// 		overflow-y: auto;
// 	}

// 	.ant-layout-content {
// 		padding: 15px 15px 0;
// 		overflow-x: hidden;
// 		overflow-y: auto;

// 		> *:last-child {
// 			margin-bottom: 15px;
// 		}
// 	}
// }

// // header.ant-layout-header {
// // 	display: flex;
// // 	flex-direction: column;
// // 	align-items: flex-end;
// // 	height: 61px;

// // 	span {
// // 		margin-top: 10px;
// // 	}
// // }

// // .admin_header{
// // 	@include media("<=lg"){
// // 		padding-left: 20px !important;
// // 	}
// // }

// // header.ant-layout-header {
// // 	display: flex;
// // 	flex-direction: column;
// // 	align-items: flex-end;
// // 	height: 61px;

// // 	@include media("<=lg"){
// // 		flex-direction: row;
// // 		justify-content: center;
// // 		z-index: 2 !important;
// // 		align-items: center;

// // 		> button{
// // 			margin-right: auto;
// // 		}

// // 		.ant-dropdown-link{
// // 			margin-left: auto;
// // 		}


// // 	}

// // 	span {
// // 		margin-top: 10px;
// // 	}
// // }

// header.ant-layout-header {
// 	// background: #231f20;
//   //background: #36a9e1;
//   height: auto;
//   padding: 0;
//   padding-bottom: 15px;
// }

// .ant-menu.ant-menu-dark,
// .ant-menu-dark .ant-menu-sub,
// .ant-menu.ant-menu-dark .ant-menu-sub {
// 	// background: #216c97;
//   background: #383e4b;
// }

.ant-layout.main-layout {
  background-color: $color-white;
  min-height: 100vh;

  & .main-content {
    background-color: #e5e5e5;
		.ant-layout {
			height: 100%;
		}
  }
}
.manage-layout {
	background: #fff;
}

// .content-space {
//   margin-left: 250px;
//   background-color: #fff;
//   //position: relative;

//   &--side-menu-collapsed {
//     margin-left: 83px;
//   }

//   @media only screen and (max-width: 992px) {
//     margin-left: 0px;
//   }
// }
