@keyframes loading-pulsing {
  0% {
    width: 80px;
    height: 80px;
    opacity: 0.2;
  }
  100% {
    width: 100px;
    height: 100px;
    opacity: 1;
  }
}

.loading-pulse {
  animation: loading-pulsing 5s ease-out infinite;
}