.text-content-container p {
  margin-bottom: 0 !important;
}

.divider-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}