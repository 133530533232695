.opt-in {
  // height: 737px;
  font-family: $font-secondary;
  color: $color-dark;
  .ant-modal-body {
    padding: 40px 20px;
  }
  &__text {
    font-size: 14px;
    font-weight: 400;
    &--small {
      font-size: 12px;
    }
    &--verification {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
  &__title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 14px;
  }
  &__paragraph {
    margin-bottom: 14px;
  }
  &__logo-wrapper {
    text-align: center;
  }
  &__logo {
    margin-bottom: 30px;
  }
  &__form-group {
    display: flex;
    gap: 10px;
    .opt-in__text {
      flex-grow: 1;
    }
  }
  &__form-item {
    margin-bottom: 20px;
  }
  &__button {
    width: 120px;
  }
  &__link {
    color: $color-primary;
    cursor: pointer;
  }
  .ant-checkbox + span {
    padding-right: 0;
    padding-left: 10px;
  }
  .ant-modal-content,
  .ant-modal-body {
    height: 100%;
  }
  &__legal {
    // height: 85%;
    bottom: 0;
  }
  &__legal-text {
    height: 85%;
    overflow: auto;
    font-size: 0.75rem;
  }
}
.legal-link {
  color: #1890ff;
  text-decoration: none;
  outline: none;
  cursor: pointer;
}
