button {
	background: none;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    user-select:none;
	cursor:pointer;
	
	&:focus {
		outline:none;
	}
}

.video-react-button {
	display: none !important;
}