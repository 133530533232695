@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,500;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');

// FONTS
$font-primary: 'Titillium Web', sans-serif;
$font-secondary: 'Noto Sans', sans-serif;

// COLORS
// Color PRIMARY
$color-primary: #4962E2;
$color-primary-hover: #6177EC;
$color-primary-light: #DBE0F8;
$color-primary-inactive: #B6C0F3;
$color-primary-dark: #2C40A7;
$color-primary-1: #6D81E8; //not used
$color-primary-2: #92A1EE; //not used
$color-primary-3: #DCE2FF;
$color-blue-sky: #82DEFF; //not used

// Color WHITE
$color-white: #FFFFFF;
$color-off-white: #FBFBFB;
$color-white-inactive: rgba(255, 255, 255, 0.4);

// Color GREY
$color-darker-grey: #90989E;
// $color-dark-grey: #AEB8C0;
$color-dark-grey: #AEAEAE;
$color-dark-grey-hover: #BABABA;
$color-dark-grey-inactive: #DFDFDF;
$color-medium-grey: #BDC7CF;
// $color-grey: #D5DFE8; //not used
$color-grey: #E4E4E4;
$color-grey-hover: #E8E8E8;
$color-grey-inactive: #F4F4F4;
$color-light-grey: #F4F6F7;

// Color DARK
// $color-dark: #2A3A49;
$color-dark: #333333;
$color-dark-hover: #515151;
$color-dark-inactive: #ADADAD;
$color-dark-1: #41576B; //not used
$color-dark-2: #728291; //not used
$color-dark-3: #E5EDF4;  //not used

// Color WARNING RED
$color-warning-red: #FB5757;
$color-warning-red-light: #FEDFDF;
$color-warning-red-hover: #FB5E5E;
$color-warning-red-inactive: #FDBFBF;

$color-success-green: #39BB82;
$color-attention-yellow: #FBD850;

$color-black: #333333; //not used (replaced)
$color-black-1: #4D4D4D; //not used
$color-black-2: #666666; //not used
$color-black-3: #DEDEDE; //not used

$color-blue: #3C9AEF;
$color-blue-1: #63AEF2; //not used 
$color-blue-2: #8AC2F5; //not used
$color-blue-3: #D9EDFF; //not used

$color-peach: #FFC197;
$color-peach-1: #FFC9A4; //not used
$color-peach-2: #FFD8BE; //not used
$color-peach-3: #FFF3EB; //not used

$color-mint: #31DDD4;
$color-mint-1: #65E6DF; //not used
$color-mint-2: #83EBE5; //not used
$color-mint-3: #D3FFFD; //not used

$color-pink: #FF7BAC;
$color-pink-1: #FF95BD;
$color-pink-2: #FFB0CD;
$color-pink-3: #FFEDF4;

//stare boje, obrisati kasnije
$bgColor:#fff;
$bodyColor:#f0f2f5;
$wht:#fff;

$k2:#fafafa;
$k3:#f7f7f7;
$k5:#f2f2f2;
$k10:#e6e6e6;
$k15:#d9d9d9;
$k20:#ccc;
$k25:#bfbfbf;
$k30:#b3b3b3;
$k40:#999;
$k50:#808080;
$k60:#666;
$k70:#4d4d4d;
$k80:#333;
$k90:#191919;
$k100:#000;

$base:#0071bc;
$txtColor:#696c74;

// $c-primary:#259dab; 
//$c-primary:#4F66A0;
$c-primary:#216c97;
// $c-secondary:#464f63; 
//$c-secondary:#DBE2EA;
$c-secondary:#DBE2EA;
$c-tertiary: #0092dd;

// $light:#f5f5f5;
$line: #ddd;
// $line2: #bdbdbd;
$darkBlue:#2e3192;
$hl: #ffcc00;
// $hl2: #e6e7ee;
// $blue: #0600e0;

$highlight:#5bbfde;
$danger: #d9534f;

$success:#a1ca51;
$error:#ce0000;

$purple:#444573;
$red:$error;
$yellow:#d5c93f;
$orange:orange;
$green:$success;
$gray:#5d737e;

//$bg-grey: rgba(228, 236, 244, 0.6);
$bg-grey: #EFF4F8;

$c-blue: #3B8DE0;

// $font-stack: "Lato", sans-serif; 
$font-stack: 'Titillium Web', sans-serif;

// FONTS

// Font BODY
$font-body-weight: 400;
$font-body-size: 14px;
$font-body-line-height: 19px;

// Font BUTTON LABEL
$font-button-weight: 600;
$font-button-size: 14px;
$font-button-line-height: 22px;

// Font INPUT LABEL
$font-label-weight: 400;
$font-label-size: 14px;
$font-label-line-height: 19px;

// Font HEADING
$font-heading-size: 24px;
$font-heading-line-height: 37px;

// Font TOOLBAR CAPTION
$font-toolbar-weight: 400;
$font-toolbar-weight-selected: 500;
$font-toolbar-size: 11px;
$font-toolbar-line-height: 15px;

// Font LEFT MENU ITEM
$font-left-menu-item-weight: 500;
$font-left-menu-item-size: 12px;
$font-left-menu-item-line-height: 16px;
$font-left-menu-item-letter-spacing: 0.1em;
$font-left-menu-item-text-transform: uppercase;

// Font SIDEBAR
$font-sidebar-weight: 400;
$font-sidebar-weight-selected: 500;
$font-sidebar-size: 16px;
$font-sidebar-line-height: 160%;

// Font TOPBAR
$font-topbar-weight: 400;
$font-topbar-weight-selected: 500;
$font-topbar-size: 16px;
$font-topbar-line-height: 160%;