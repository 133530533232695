.content {
    height: 100%;
    position: relative;
}
.video {
    width: 100%;
    height: 100%;
    position: absolute;
}
.video-react {
    background-color: unset;
    position: unset;
}
.video-react.video-react-fluid, .video-react.video-react-16-9, .video-react.video-react-4-3 {
    width: 100%;
    max-width: 100%;
    height: 0;
}
.video-react .video-react-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}